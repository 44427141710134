// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.videoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.qrVideo {
  object-fit: cover;
  border: 1px solid #ddd;
  width: 300px;
  height: 300px;
  border-radius: 30px;
}

.scannedText {
    word-wrap: break-word;
  }
  `, "",{"version":3,"sources":["webpack://./src/widgets/qr/scanner/Scanner.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;IACI,qBAAqB;EACvB","sourcesContent":[".videoWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n}\n\n.qrVideo {\n  object-fit: cover;\n  border: 1px solid #ddd;\n  width: 300px;\n  height: 300px;\n  border-radius: 30px;\n}\n\n.scannedText {\n    word-wrap: break-word;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
