// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    display: flex;
    flex-direction: column;
}

.modal__header {
  display: flex;
  padding: 5px;
  font-size: 14px;
  text-align: center;
}

.modal__title {
    flex-grow: 1;
    color:white;
    font-size:14px;
    font-weight: 400;
}

.modal__content {
  padding: 5px;
  flex-grow: 1;
  min-height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/qr/modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,kBAAkB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,cAAc;IACd,gBAAgB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".modal {\n    display: flex;\n    flex-direction: column;\n}\n\n.modal__header {\n  display: flex;\n  padding: 5px;\n  font-size: 14px;\n  text-align: center;\n}\n\n.modal__title {\n    flex-grow: 1;\n    color:white;\n    font-size:14px;\n    font-weight: 400;\n}\n\n.modal__content {\n  padding: 5px;\n  flex-grow: 1;\n  min-height: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
