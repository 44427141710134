// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appContainer {
    position: fixed;
    background:rgba(0,0,0,0.75);
    top: 0;
    right:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 99;
    justify-content: center;
    align-items: center;
}

.appContainer .appContainer_btn{
    position:absolute;
    top:1rem;
    right:1rem;
}
  
  .main {
    text-align: center;
    padding: 10px;
    width: 80%;
    border-radius: 10px;
    background: #d5e0e8;
    box-shadow: 0 10px 20px #ccc;
  }`, "",{"version":3,"sources":["webpack://./src/widgets/qr/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,2BAA2B;IAC3B,MAAM;IAEN,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,QAAQ;IACR,UAAU;AACd;;EAEE;IACE,kBAAkB;IAClB,aAAa;IACb,UAAU;IACV,mBAAmB;IACnB,mBAAmB;IACnB,4BAA4B;EAC9B","sourcesContent":[".appContainer {\n    position: fixed;\n    background:rgba(0,0,0,0.75);\n    top: 0;\n    left: 0;\n    right:0;\n    left:0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    z-index: 99;\n    justify-content: center;\n    align-items: center;\n}\n\n.appContainer .appContainer_btn{\n    position:absolute;\n    top:1rem;\n    right:1rem;\n}\n  \n  .main {\n    text-align: center;\n    padding: 10px;\n    width: 80%;\n    border-radius: 10px;\n    background: #d5e0e8;\n    box-shadow: 0 10px 20px #ccc;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
