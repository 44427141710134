// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table table { font-size: 12px; }
.ant-pagination-simple-pager {font-size :12px;}
.ant-pagination-simple-pager input {font-size :12px; padding:0}
.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-center.css-dev-only-do-not-override-1pg9a38.ant-pagination-simple {
  margin:0;
  margin-bottom: 1rem;
}

.ant-select-selection-item{
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,sBAAsB;AACxB;;AAEA;EACE;aACW;AACb;;AAEA,mBAAmB,eAAe,EAAE;AACpC,8BAA8B,eAAe,CAAC;AAC9C,oCAAoC,eAAe,EAAE,SAAS;AAC9D;EACE,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  box-sizing: border-box;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.ant-table table { font-size: 12px; }\n.ant-pagination-simple-pager {font-size :12px;}\n.ant-pagination-simple-pager input {font-size :12px; padding:0}\n.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-center.css-dev-only-do-not-override-1pg9a38.ant-pagination-simple {\n  margin:0;\n  margin-bottom: 1rem;\n}\n\n.ant-select-selection-item{\n  font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
